<template>
  <div class="container py-12 bg-white">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          Terms and Conditions of nyunlock.com
        </h2>
        <p class="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">
          At nyunlock.com, we collect and manage user data according to the following
          <a href="/privacy-plicy">Privacy Policy</a>.
        </p>
      </div>

      <div class="mt-10">
        <div class="leading-loose">
          <h3 class="mt-3 text-2xl">GENERAL TERMS AND CONDITIONS</h3>
          <br /><b>
            -
            <font color="red"
              >The use of Automated Bots to retrieve information from the free pages is strictly
              prohibited!</font
            ><br /><br />
            - Services prices or result(info)/ format may change at any time.<br /><br />
            - The dashboard is exclusively for customers who have bulk orders! So we will NOT
            refound the credit if the total amount added / used on the site is less than $ 5 with
            one exception, if the service used by the user in the past has been disabled. (eg if you
            add $ 5 just to do a check, then ask for a refund for the rest of the amount - this will
            NOT be possible under any circumstances) <br /><br />
            - The customer assumes the responsibility to fully understand the use of our services,
            especially as the services only provide information and do NOT involve decoding /
            unlocking the phone. <br /><br />
            - If the IMEI / SN is wrong or the wrong model(eg: Samsung IMEI's on Apple Services),
            more than 25% of the orders we assume the freedom to permanently close the account (or
            deduct $0.01 for each wrong IMEI - if the services, although they do not charge money
            for the wrong orders, provide information (eg: Apple Find my Phone check although it
            returns the money for the wrong orders, it still offers brand + model as return
            information.) <br /><br />
            - This data is provided 'as is'. Neither nyunlock.com, or the provider of the data,
            represents or guarantees, or accepts any liabilities whatsoever in respect of, the
            accuracy, currency or completeness of any of this data.<br /><br />
            - You agree to take responsibility for the safekeeping of your user name and password.
            You are liable if your user name or password is used by an unauthorised person. You
            agree to release and indemnify nyunlock.com in connection with any use (whether
            authorised or unauthorised) of your user name or password. nyunlock.com may suspend or
            cancel your account at any time without prior notice. <br /><br />
            - You must not misuse the Site by knowingly or negligently introducing viruses, Trojans,
            worms, logic bombs or other material which is malicious or technologically harmful. You
            must not attempt to gain unauthorised access to the Site, the server on which the Site
            is hosted or any server, computer or similar device or database connected to the Site.
            You must not attack the Site via a denial-of-service attack or a distributed denial-of
            service attack. If you breach this clause, all rights granted to you to use the Site
            will immediately revoke and cease.
            <font color="red"
              >Any attempt to defraud or violate the terms described above will have legal
              consequences!</font
            ><br /><br />
            - The trademarks, logos, and service marks (together with the “Marks”) displayed on the
            Site are registered or unregistered Marks of An Post or Our licensors. All rights in the
            Marks are reserved. These Terms do not grant and nothing in these Terms are to be
            construed as granting any right to use any of the Marks without Our prior consent or
            that of Our licensors. <br /><br />
            - We may from time to time revise these Terms by amending this page. You will be bound
            by such revised terms and conditions when you use the Site and you should therefore
            periodically check this page for any amendments we make.
          </b>
          <br /><br />
          <h3 class="mt-3 text-2xl">REFOUNDS</h3>
          <br />
          <b>
            - After the refund request - we assume the responsibility to transfer your money from
            the nyunlock.com account in maximum 15 working days.</b
          ><br />
          <br /><br />
          <b>
            <font color="red">
              IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE nyunlock.COM WEBSITE OR THE
              SERVICES!</font
            ></b
          >
        </div>
      </div>
    </div>
  </div>
</template>
