<template>
  <div class="container py-12 bg-white">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          {{ $t('faq.title') }}
        </h2>
      </div>

      <div class="mt-10">
        <div class="w-full px-4">
          <div class="w-full p-2 mx-auto bg-white rounded-2xl">
            <Disclosure
              as="div"
              class="mt-2"
              v-for="item in faqs"
              :key="item.title"
              v-slot="{ open }"
            >
              <DisclosureButton
                class="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
              >
                <span>{{ item.title }}</span>
                <ChevronUpIcon
                  :class="open ? 'rotate-180 transform' : ''"
                  class="w-5 h-5 text-purple-500"
                />
              </DisclosureButton>
              <DisclosurePanel class="px-4 pt-4 pb-2 text-sm text-gray-500">
                {{ item.content }}
              </DisclosurePanel>
            </Disclosure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/24/solid';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const faqs = [];
[...new Array(9).keys()].forEach((f) => {
  faqs.push({
    title: t(`faq.q${f + 1}`),
    content: t(`faq.a${f + 1}`),
  });
});
// console.log(faq);

// const faqs = [
//   {
//     title: t('faq.q1'),
//     content: t('faq.a1'),
//   },
//   {
//     title: 'How can I find my phone’s IMEI? ',
//     content:
//       'You can easily find your phone’s IMEI number by simply dialing *#06#. Typically, you don’t need to press anything else on the phone, as the IMEI will appear as soon as you finish dialing the code.',
//   },
//   {
//     title: 'Is checking my phone’s IMEI safe? ',
//     content:
//       'Yes, there’s absolutely nothing to worry about checking your phone’s IMEI number. It’s a basic procedure that cannot harm your phone in any way, as everything it involves is finding information related to your phone.',
//   },
//   {
//     title: 'What’s a blacklisted IMEI?',
//     content:
//       'A phone’s IMEI can be blacklisted if the device was reported as stolen or lost. After the user reports the handset, its IMEI will be added to most operators’ databases, thus restricting it from being used.',
//   },
//   {
//     title: 'How can a phone be added to the blacklist?',
//     content:
//       'In case of theft or loss, the first thing you should do is contact the operator from which you purchased the phone and report it. In most cases, you will need to provide documents confirming the purchase.',
//   },
//   {
//     title: 'What is a serial number?',
//     content:
//       'A phone’s serial number is basically a code, consisting of numbers and/or letters, used to identify your device’s time and place of manufacture, as well as some basic specifications, but also determining if it’s an original product',
//   },
//   {
//     title: 'Can a phone have two IMEIs?',
//     content:
//       'Yes, if it’s a dual SIM model. This means that it has one modem, but registered to two different networks, this two network registrations, each of them requiring a unique IMEI.',
//   },
//   {
//     title: 'What is a SIM lock?',
//     content:
//       'A SIM-locked phone is a device in which you can install SIM cards issued just by the network operator you bought it from, from a specific country. They are usually asking for an unlock code when inserting a SIM from another operator.',
//   },
//   {
//     title: 'What is an eSIM?',
//     content:
//       'An eSIM, considered the successor of traditional SIM cards, is a chip built-in within a phone, which cannot be removed. The information it contains can be rewritable, meaning that changing operators is possible.',
//   },
// ];
</script>
