<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
    <div class="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block w-6 h-6" aria-hidden="true" />
            <XMarkIcon v-else class="block w-6 h-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
          <div class="flex items-center flex-shrink-0">
            <router-link to="/">
              <img
                class="block w-auto h-8 lg:hidden"
                src="../../images/logo-w.png"
                alt="nyunlock"
              />
              <img
                class="hidden w-auto h-8 lg:block"
                src="../../images/logo-w.png"
                alt="nyunlock"
              />
            </router-link>
          </div>
          <div class="hidden sm:block sm:ml-6 grow">
            <div class="flex justify-center space-x-4 main-nav">
              <Imei />
              <a
                href="/user/unlock"
                :class="navLinkClasses('/unlock')"
                :aria-current="isActive('/unlock') ? 'page' : undefined"
                >{{ $t('nav.unlock') }}</a
              >
              <router-link
                to="/pricing"
                :class="navLinkClasses('/pricing')"
                :aria-current="isActive('/pricing') ? 'page' : undefined"
                >{{ $t('nav.pricing') }}</router-link
              >
              <router-link
                to="/faq"
                :class="navLinkClasses('/faq')"
                :aria-current="isActive('/faq') ? 'page' : undefined"
                >{{ $t('nav.faq') }}</router-link
              >
              <router-link
                to="/contact"
                :class="navLinkClasses('/contact')"
                :aria-current="isActive('/contact') ? 'page' : undefined"
                >{{ $t('nav.contact') }}</router-link
              >
            </div>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <div class="hidden mr-2 md:block">
            <Locale />
          </div>

          <a
            v-if="isLogin()"
            href="/user"
            class="px-5 py-1.5 mr-2 text-sm font-medium text-white bg-[#ff7c60] rounded-lg hover:bg-[#ff7c60] focus:ring-4 dark:bg-[#ff7c60] dark:hover:bg-[#ff7c60] focus:outline-none"
            >{{ $t('nav.user') }}</a
          >
          <a
            v-else
            href="/auth/login"
            class="px-5 py-1.5 mr-2 text-sm font-medium text-white bg-[#ff7c60] rounded-lg hover:bg-[#ff7c60] focus:ring-4 dark:bg-[#ff7c60] dark:hover:bg-[#ff7c60] focus:outline-none"
          >
            {{ $t('nav.login') }}
          </a>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 bg-gray-700">
        <DisclosureButton
          as="a"
          href="/imei-check"
          :class="navDisclosureClasses('/imei-check')"
          :aria-current="isActive('/imei-check') ? 'page' : undefined"
          >{{ $t('nav.checkimei') }}</DisclosureButton
        >
        <DisclosureButton
          as="a"
          href="/pricing"
          :class="navDisclosureClasses('/pricing')"
          :aria-current="isActive('/pricing') ? 'page' : undefined"
          >{{ $t('nav.pricing') }}</DisclosureButton
        >
        <DisclosureButton
          as="a"
          href="/faq"
          :class="navDisclosureClasses('/faq')"
          :aria-current="isActive('/faq') ? 'page' : undefined"
          >{{ $t('nav.faq') }}</DisclosureButton
        >
        <DisclosureButton
          as="a"
          href="/contact"
          :class="navDisclosureClasses('/contact')"
          :aria-current="isActive('/contact') ? 'page' : undefined"
          >{{ $t('nav.contact') }}</DisclosureButton
        >
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { computed } from 'vue';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import { BellIcon, Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import Locale from '@/views/shared/_locale.vue';
import Imei from '@/views/shared/_imei.vue';
import { useRoute } from 'vue-router';
import { isLogin } from '@/utils/auth';

const route = useRoute();

const navLinkClasses = computed(() => (path) => {
  if (path === route.path) {
    return 'bg-gray-600 text-white px-3 py-2 rounded-md text-lg font-medium';
  } else {
    return 'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-lg font-medium';
  }
});

const navDisclosureClasses = computed(() => (path) => {
  if (path === route.path) {
    return 'bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium';
  } else {
    return 'text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium';
  }
});

const isActive = computed(() => (path) => {
  return path === route.path;
});
</script>
