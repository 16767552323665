<template>
  <div class="container py-12 bg-white">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          Privacy Policy of nyunlock.com
        </h2>
        <p class="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">
          At nyunlock.com, we collect and manage user data according to the following Privacy
          Policy.
        </p>
      </div>

      <div class="mt-10">
        <div class="leading-loose">
          <b
            ><center><font color="red">FREE CHECKS Privacy Policy / DATA COLECTION:</font></center>
            - We save the IMEIs/ SN / IP / Cookies used in the free checks pages for a certain
            period, in order to be able to limit the number of free checks for each visitor.<br />
            - This data will not be sold or offered to other companies. We generally completely
            clean the database once every 3-6 months. <br />
            - However, we will save and permanently block IPs that attempt DOSS attacks, or attempt
            to use automatic bots.
            <br /><br />
            <center><font color="red">PAID CHECKS Privacy Policy / DATA COLECTION:</font></center>
            - Data processed in the dashboard (IMEI / SERIAL / IP / DATA REPLY, LOGS, and other
            data) will be saved for an indefinite period of time!</b
          ><br />
          (We generally delete the data every 3 years, but this is not a rule)
          <b
            ><br />
            - You can contact us to delete all data as well as your account!
          </b>
          <br /><br />
          <h3 class="mt-3 text-2xl">Google reCAPTCHA Privacy Policy</h3>
          <p>
            "Google reCAPTCHA" (hereinafter "reCAPTCHA") is implemented on our websites. This
            service is provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
            USA ("Google").
          </p>
          <p>
            <strong
              >We use reCHAPTA on our websites to prevent various types of data processing abuse
              attempts (e.g. phishing). reCHAPTA analyzes various information of the respective
              visitor (e.g. IP address, mouse movements of the user, length of stay on the website).
              By using reCAPTCHA, data is transferred to Google, which is used to determine whether
              the visitor is a human being or a (spam) bot.</strong
            >
          </p>
          <p>
            The data processing is based on Art. 6 para. 1 lit. f GDPR. The website operator has a
            legitimate interest in protecting its web offers against abusive automated spying,
            against spam as well as protecting third parties against such attacks.
          </p>
          <p>
            You can read which data is collected by Google and what this data is used for at
            https://policies.google.com/privacy.
          </p>
          <p>
            You can read the terms of use for Google services and products at
            https://policies.google.com/terms.
          </p>
          <h3 class="mt-3 text-2xl">Data Collected</h3>
          <p>
            We collect information you provide directly to us. For example, we collect information
            when you create an account, subscribe, participate in any interactive features of our
            services, fill out a form, request customer support or otherwise communicate with us.
            The types of information we may collect include your name, email address, postal
            address, credit card information and other contact or identifying information you choose
            to provide.
          </p>
          <p>
            We collect anonymous data from every visitor of the Website to monitor traffic and fix
            bugs. For example, we collect information like web requests, the data sent in response
            to such requests, the Internet Protocol address, the browser type, the browser language,
            and a timestamp for the request.
          </p>
          <p>
            We also use various technologies to collect information, and this may include sending
            cookies to your computer. Cookies are small data files stored on your hard drive or in
            your device memory that helps us to improve our services and your experience, see which
            areas and features of our services are popular and count visits. We may also collect
            information using web beacons (also known as "tracking pixels"). Web beacons are
            electronic images that may be used in our services or emails and to track count visits
            or understand usage and campaign effectiveness.
          </p>
          <h3 class="mt-3 text-2xl">Use of the Data</h3>
          <p>
            We only use your personal information to provide you the
            <span class="website_name">nyunlock.com</span> services or to communicate with you about
            the Website or the services.
          </p>
          <p>
            We employ industry standard techniques to protect against unauthorized access of data
            about you that we store, including personal information.
          </p>
          <p>
            We do not share personal information you have provided to us without your consent,
            unless:
          </p>
          <ul>
            <li>Doing so is appropriate to carry out your own request</li>
            <li>
              We believe it's needed to enforce our legal agreements or that is legally required
            </li>
            <li>
              We believe it's needed to detect, prevent or address fraud, security or technical
              issues
            </li>
          </ul>
          <h3 class="mt-3 text-2xl">Sharing of Data</h3>
          <p>
            We don't share your personal information with third parties. Aggregated, anonymized data
            is periodically transmitted to external services to help us improve the Website and
            service.
          </p>
          <p>
            We may allow third parties to provide analytics services. These third parties may use
            cookies, web beacons and other technologies to collect information about your use of the
            services and other websites, including your IP address, web browser, pages viewed, time
            spent on pages, links clicked and conversion information.
          </p>
          <p>
            We also use social buttons provided by services like Twitter, Google+, LinkedIn and
            Facebook. Your use of these third party services is entirely optional. We are not
            responsible for the privacy policies and/or practices of these third party services, and
            you are responsible for reading and understanding those third party services' privacy
            policies.
          </p>
          <h3 class="mt-3 text-2xl"><strong>Cookies Policy</strong></h3>
          <p>
            IMEI CHECK uses cookies on nyunlock.com. By using the Service, you consent to the use of
            cookies..
          </p>
          <p>
            Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we
            may partner with may use cookies on the Service, your choices regarding cookies and
            further information about cookies.
          </p>
          <p><strong>What are cookies</strong></p>
          <p>
            Cookies are small pieces of text sent by your web browser by a website you visit. A
            cookie file is stored in your web browser and allows the Service or a third-party to
            recognize you and make your next visit easier and the Service more useful to you.
          </p>
          <p><strong>How nyunlock.com uses cookies</strong></p>
          <p>
            When you use and access the Service, we may place a number of cookies files in your web
            browser.
          </p>
          <p>
            We use cookies for the following purposes: to enable certain functions of the Service,
            to provide analytics, to store your preferences, to enable advertisements delivery,
            including behavioral advertising.
          </p>
          <p>
            We use both session and persistent cookies on the Service and we use different types of
            cookies to run the Service:
          </p>
          <p>
            - Essential cookies. We may use essential cookies to authenticate users and prevent
            fraudulent use of user accounts.
          </p>
          <p>
            - Third-party cookies. In addition to our own cookies, we may also use various
            third-parties cookies to report usage statistics of the Service, deliver advertisements
            on and through the Service, and so on
          </p>
          <h3 class="mt-3 text-2xl">Opt-Out, Communication Preferences</h3>
          <p>
            You may modify your communication preferences and/or opt-out from specific
            communications at any time. Please specify and adjust your preferences.
          </p>
          <h3>Security</h3>
          <p>
            We take reasonable steps to protect personally identifiable information from loss,
            misuse, and unauthorized access, disclosure, alteration, or destruction. But, you should
            keep in mind that no Internet transmission is ever completely secure or error-free. In
            particular, email sent to or from the Sites may not be secure.
          </p>
          <h3>About Children</h3>
          <p>
            The Website is not intended for children under the age of 13. We do not knowingly
            collect personally identifiable information via the Website from visitors in this age
            group.
          </p>
          <h3 class="mt-3 text-2xl">Changes to the Privacy Policy</h3>
          <p>
            We may amend this Privacy Policy from time to time. Use of information we collect now is
            subject to the Privacy Policy in effect at the time such information is used.
          </p>
          <p>
            If we make major changes in the way we collect or use information, we will notify you by
            posting an announcement on the Website or sending you an email.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
