<script setup>
import { reactive } from 'vue';
import Field from '@/components/Field.vue';
import Control from '@/components/Control.vue';
import { useFeedbackStore } from '@/stores/feedback';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { Form } from 'vee-validate';
import * as Yup from 'yup';
import { useToast } from 'primevue/usetoast';

const router = useRouter();
const { t } = useI18n();
const feedbackStore = useFeedbackStore();
const toast = useToast();

const form = reactive({
  name: '',
  email: '',
  phone: '',
  answer: '',
  subject: '',
  message: '',
});

const validationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required().email(),
  answer: Yup.string().required().matches('17'),
  subject: Yup.string().required(),
  message: Yup.string().required(),
});

const onSubmit = async (values, { resetForm }) => {
  // console.log(resetForm);
  if (values.answer === '17') {
    feedbackStore.feedback = values;
    await feedbackStore.create().then((res) => {
      if (res) {
        toast.add({
          severity: 'success',
          summary: 'Send successfully',
          life: 3000,
        });
        // resetForm({ values: { name: '' } });
        router.push('/contact');
      } else {
        toast.add({
          severity: 'warn',
          summary: 'Send failed',
          detail: `${res.error}`,
          life: 6000,
        });
      }
    });
  } else {
    toast.add({
      severity: 'warn',
      summary: 'Answer is wrong',
      life: 6000,
    });
  }
};

const features = [
  {
    name: 'Skype',
    description: t('contact.skype'),
    icon: 'ri-skype-line',
  },
  {
    name: 'Whatsapp No',
    description: t('contact.whatsapp'),
    icon: 'ri-whatsapp-line',
  },
  {
    name: 'Wechat',
    description: 'ID: nyunlock',
    icon: 'ri-wechat-line',
  },
  {
    name: 'E-mail',
    description: 'support@nyunlock.com',
    icon: 'ri-mail-line',
  },
  {
    name: 'Address',
    description: 'Ireland-Wexford-Main street',
    icon: 'ri-map-pin-line',
  },
];
</script>

<template>
  <div class="container py-12 bg-white">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="mt-2 text-3xl font-medium leading-8 tracking-tight text-gray-900 sm:text-4xl">
          {{ $t('contact.title1') }} <span class="font-extrabold">{{ $t('contact.title2') }}</span>
        </h2>
        <p class="max-w-2xl mt-4 text-base font-medium lg:mx-auto">
          {{ $t('contact.desc3') }}NYunlock@outlook.com
        </p>
      </div>

      <!-- <div class="mt-10 border border-gray-300 sm:rounded-xl">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="bg-blue-800 md:col-span-1 sm:rounded-l-xl">
            <div class="px-4 py-10 sm:px-4">
              <h3 class="text-3xl font-medium leading-6 text-white">
                {{ $t('contact.quick_title') }}
              </h3>
              <div class="mt-10">
                <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10">
                  <div v-for="feature in features" :key="feature.name" class="relative">
                    <dt>
                      <div
                        class="absolute flex items-center justify-center w-12 h-12 text-white rounded-md"
                      >
                        <i :class="feature.icon" class="ri-3x"></i>
                      </div>
                      <p class="ml-16 text-lg font-medium leading-6 text-white">
                        {{ feature.name }}
                      </p>
                    </dt>
                    <dd class="mt-2 ml-16 text-base text-gray-300">
                      {{ feature.description }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <Form
              :validation-schema="validationSchema"
              v-slot="{ handleSubmit, resetForm, isSubmitting, values, submitForm, errors }"
              as="div"
            >
              <form @submit="handleSubmit($event, onSubmit)">
                <div class="overflow-hidden">
                  <div class="px-4 py-5 sm:p-6">
                    <div class="pt-5 pb-14">
                      <h3 class="text-3xl font-medium leading-6">{{ $t('contact.formtitle') }}</h3>
                    </div>
                    <div class="grid grid-cols-6 gap-y-2 gap-x-4">
                      <field :label="$t('contact.name')" class="col-span-6 sm:col-span-3">
                        <control
                          v-model="form.name"
                          name="name"
                          autocomplete="name"
                          :placeholder="$t('contact.namep')"
                        />
                      </field>

                      <field :label="$t('contact.email')" class="col-span-6 sm:col-span-3">
                        <control
                          v-model="form.email"
                          name="email"
                          autocomplete="email"
                          :placeholder="$t('contact.emailp')"
                        />
                      </field>

                      <field :label="$t('contact.phone')" class="col-span-6 sm:col-span-3">
                        <control
                          v-model="form.phone"
                          name="phone"
                          autocomplete="phone"
                          :placeholder="$t('contact.phonep')"
                        />
                      </field>

                      <field label="5+12=" class="col-span-6 sm:col-span-3">
                        <control
                          v-model="form.answer"
                          name="answer"
                          autocomplete="answer"
                          :placeholder="$t('contact.answerp')"
                        />
                      </field>

                      <field :label="$t('contact.subject')" class="col-span-6">
                        <control
                          v-model="form.subject"
                          name="subject"
                          autocomplete="subject"
                          :placeholder="$t('contact.subjectp')"
                        />
                      </field>

                      <field :label="$t('contact.message')" class="col-span-6">
                        <control
                          v-model="form.message"
                          name="message"
                          type="textarea"
                          :placeholder="$t('contact.messagep')"
                        />
                      </field>
                    </div>
                  </div>
                  <div class="px-4 py-3 text-center sm:px-6">
                    <button
                      type="submit"
                      class="inline-flex justify-center px-10 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {{ $t('contact.send_message') }}
                    </button>
                  </div>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
