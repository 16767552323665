<template>
  <div class="pt-3 mb-3 border-t-2 mt-9">
    <div class="container flex flex-col justify-between text-center md:flex-row-reverse">
      <ul class="flex justify-center md:justify-start">
        <li class="p-3">
          <a href="#"><img src="../../images/icons/facebook.svg" alt="" /></a>
        </li>
        <li class="p-3">
          <a href="#"><img src="../../images/icons/twitter.svg" alt="" /></a>
        </li>
        <li class="p-3">
          <a href="#"><img src="../../images/icons/youtube.svg" alt="" /></a>
        </li>
        <li class="p-3">
          <a href="#"><img src="../../images/icons/insta.svg" alt="" /></a>
        </li>
      </ul>
      <div class="flex flex-col items-center justify-start md:flex-row-reverse">
        <ul
          class="flex flex-col items-center justify-start text-center list-none md:flex-row md:divide-x-2 md:divide-solid md:divide-light-800"
        >
          <li class="px-3 my-1">
            <a href="/terms">{{ $t('nav.terms') }}</a>
          </li>
          <li class="px-3 my-1">
            <a href="/privacy-policy">{{ $t('nav.privacy') }}</a>
          </li>
          <li class="px-3 my-1">
            <a href="#">{{ $t('nav.about') }}</a>
          </li>
          <li class="px-3 my-1">
            Visits: <span class="font-bold">{{ amount }}</span>
          </li>
        </ul>
        <span class="px-3 my-1">Copyright ©2022 All rights reserved</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useVisitStore } from '@/stores/visit';
import { storeToRefs } from 'pinia';

const visitStore = useVisitStore();

const { visit } = storeToRefs(visitStore);

const amount = ref('');

onMounted(() => {
  visitStore.index().then((res) => {
    amount.value = res.toLocaleString('en-US');
  });
});
</script>
