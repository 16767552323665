import { defineStore } from 'pinia';

export const useVisitStore = defineStore('visit', {
  state: () => {
    return {
      errors: {},
      visit: {},
      visits: [],
    };
  },

  actions: {
    async index() {
      return await this.axios.get('/visits').then((res) => {
        this.visit = res.data.data;
        return res.data.data.attributes.amount
      });
    },
  },
});
