import { createApp } from 'vue';
const app = createApp(Layout);

import Router from '@/routes.js';
import Layout from '@/views/shared/layout.vue';
import Axios from 'axios';

// From animations + Axios
import { createApi } from '@/plugins/api';
const Api = createApi({ handler: Axios, namespace: '' });

// Pinia + Axios setup
import { createPinia } from 'pinia';
const Pinia = createPinia();
Pinia.use(({ store }) => {
  store.axios = Axios;
});

// I18n loader
import { createI18n } from 'vue-i18n'; // Need the /index to avoid warning in console
const I18n = createI18n({ locale: 'current', messages: translations });

import '../stylesheets/tailwind.css';

import 'flowbite';

import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

app
  .use(Router)
  .use(I18n)
  .use(Api)
  .use(PrimeVue, {
    theme: {
      preset: Aura
    }
  })
  .use(ToastService)
  .use(Pinia)
  .mount('#app');

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Toast', Toast);
