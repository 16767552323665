<template>
  <div class="container py-12 bg-white">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="mb-8 lg:text-center">
        <h2
          class="mt-2 mb-5 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl"
        >
          {{ $t('pricing.title') }}
        </h2>
        <p class="mt-4 mb-3 text-base font-bold text-gray-800 lg:mx-auto">{{ $t('pricing.p1') }}</p>
        <p>{{ $t('pricing.p2') }}</p>
        <p>{{ $t('pricing.p3') }}</p>
      </div>

      <!-- pricing card start -->
      <div class="grid gap-3 md:grid-cols-3 sm:mb-5 md:mb-20">
        <div
          class="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700"
        >
          <div class="">
            <div class="flex flex-col items-center py-10">
              <img class="w-24 h-24 mb-3" src="../../images/icons/pricing-img1.svg" />
              <h5 class="mb-1 text-2xl font-medium text-center text-gray-900 dark:text-white">
                {{ $t('pricing.generic')
                }}<span class="block">{{ $t('pricing.check_service') }}</span>
              </h5>
            </div>
            <Accordion value="0">
              <AccordionPanel v-for="(item, index) in generics" :key="item.name" :value="index">
                <AccordionHeader>
                  <div class="flex justify-between w-full">
                    <div>{{ item.name }}</div>
                    <div>{{ item.credit }}</div>
                  </div>
                </AccordionHeader>
                <AccordionContent>
                  <p v-html="item.sample"></p>
                </AccordionContent>
              </AccordionPanel>
            </Accordion>
          </div>
          <div class="py-8 text-center">
            <a
              href="/auth/login"
              class="inline-flex justify-center px-10 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ $t('pricing.login_to_see_more') }}
            </a>
          </div>
        </div>

        <div
          class="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700"
        >
          <div class="">
            <div class="flex flex-col items-center py-10">
              <img class="w-24 h-24 mb-3" src="../../images/icons/pricing-img2.svg" />
              <h5 class="mb-1 text-2xl font-medium text-center text-gray-900 dark:text-white">
                {{ $t('pricing.apple')
                }}<span class="block">{{ $t('pricing.check_service') }}</span>
              </h5>
            </div>
            <Accordion value="0">
              <AccordionPanel v-for="(item, index) in apples" :key="item.name" :value="index">
                <AccordionHeader>
                  <div class="flex justify-between w-full">
                    <div>{{ item.name }}</div>
                    <div>{{ item.credit }}</div>
                  </div>
                </AccordionHeader>
                <AccordionContent>
                  <p v-html="item.sample"></p>
                </AccordionContent>
              </AccordionPanel>
            </Accordion>
          </div>
          <div class="py-8 text-center">
            <a
              href="/auth/login"
              class="inline-flex justify-center px-10 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ $t('pricing.login_to_see_more') }}
            </a>
          </div>
        </div>

        <div
          class="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700"
        >
          <div class="">
            <div class="flex flex-col items-center py-10">
              <img class="w-24 h-24 mb-3" src="../../images/icons/pricing-img3.svg" />
              <h5 class="mb-1 text-2xl font-medium text-center text-gray-900 dark:text-white">
                {{ $t('pricing.unlock')
                }}<span class="block">{{ $t('pricing.imei_service') }}</span>
              </h5>
            </div>
          </div>
          <!-- <div class="text-center">
            <i class="mb-4 text-2xl text-yellow-600 pi pi-bell"></i
            ><i class="mb-4 text-2xl text-yellow-600 pi pi-bolt"></i>
            <p class="mb-3 font-bold text-red-600">{{ $t('pricing.unlock_desc1') }}</p>
            <p class="text-blue-800">{{ $t('pricing.unlock_desc2') }}</p>
          </div> -->
          <!-- <div class="py-8 text-center">
            <a
              href="/auth/login"
              class="inline-flex justify-center px-10 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ $t('pricing.login_to_see_more') }}
            </a>
          </div> -->
        </div>
      </div>
      <!-- pricing card end -->

      <div class="mb-12 md:text-center">
        <h2 class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          {{ $t('pricing.subtitle') }}
        </h2>
        <p class="mt-4 mb-3 text-base font-bold text-gray-800 lg:mx-auto">
          {{ $t('pricing.subdesc') }}
        </p>
        <div class="mt-6 text-center">
          <a
            href="/auth/signup"
            class="text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 mr-2 mb-2"
          >
            {{ $t('pricing.create_account') }}
          </a>
        </div>
      </div>

      <div class="mb-10">
        <ul class="grid items-center grid-cols-2 gap-4">
          <li>
            <img src="../../images/brands/stripe.png" class="mx-auto" alt="..." />
          </li>
          <li>
            <img src="../../images/brands/paypal.png" class="mx-auto" alt="..." />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useServiceStore } from '@/stores/service';
const serviceStore = useServiceStore();

import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';

onMounted(() => {
  serviceStore.generic();
  serviceStore.apple();
});

const generics = computed(() => {
  const options = [];
  serviceStore.generics.forEach((s) => {
    options.push({
      index: s.id,
      name: s.attributes.name,
      credit: `$${s.attributes.system_price.credit}`,
      sample: s.attributes.sample,
    });
  });
  return options;
});

const apples = computed(() => {
  const options = [];
  serviceStore.apples.forEach((s) => {
    options.push({
      index: s.id,
      name: s.attributes.name,
      credit: `$${s.attributes.system_price?.credit}`,
      sample: s.attributes.sample,
    });
  });
  return options;
});
</script>

<style lang="scss" scoped>
::v-deep(.p-accordion-header-link) {
  display: block !important;
  width: 100%;
  /* justify-content: space-between; */
  /* justify-items: start; */
}
::v-deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
  font-weight: 500;
  color: #666;
  border-left-width: 0;
  border-right-width: 0;
  background: #fff;
}
::v-deep(.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
::v-deep(
    .p-accordion
      .p-accordion-tab:last-child
      .p-accordion-header:not(.p-highlight)
      .p-accordion-header-link
  ) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
::v-deep(.p-accordion-toggle-icon.pi.pi-chevron-right) {
  display: none !important;
}
::v-deep(.p-accordion-toggle-icon.pi.pi-chevron-down) {
  display: none !important;
}
</style>
